<template>
  <b-container class="mt-5">

    <b-row class="justify-content-center">
      <b-col md="9">
        <b-overlay
          variant="white"
          :show="isLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-card>
            <div class="text-center mb-4">
              <h2 class="font-weight-bold">
                Congratulations!
              </h2>
              <p>You have completed the RIASEC test.</p>
              <h4>Your Top 3 Interest Codes:</h4>
              <h3 class="my-4">
                <b-badge
                  v-for="code in topInterestCodes"
                  :key="code"
                  variant="warning"
                  class="mx-1"
                >
                  {{ code }}
                </b-badge>
              </h3>
              <h5>Suggested Industries and Majors for You:</h5>
              <div class="my-1">
                <b-badge
                  v-for="industry in compatibleIndustries"
                  :key="industry"
                  variant="primary"
                  class="m-1"
                >
                  {{ industry }}
                </b-badge>
              </div>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  data() {
    return {
      topInterestCodes: [], // To be filled from the backend
      compatibleIndustries: [], // To be populated based on top codes
      isLoading: true,
    }
  },
  async created() {
    await this.fetchInterestCodesFromBackend()
    this.populateCompatibleIndustries()
  },
  methods: {
    async fetchInterestCodesFromBackend() {
      this.isLoading = true
      // Fetch the RIASEC results from the backend
      const { riasec_id } = this.$route.params // Assume riasec_id is available in the route params
      try {
        const response = await this.$http.get(`/api/riasec/results/${riasec_id}`)
        const result = response.data.results
        this.isLoading = false

        // Set the top three interest codes based on the backend data
        this.topInterestCodes = result.interest_code.split('') // Assuming interest_code is a string like "RIA"
      } catch (error) {
        console.error('Failed to fetch RIASEC results:', error)
      }
    },
    populateCompatibleIndustries() {
      // Define the compatible industries based on RIASEC codes
      const industries = {
        R: [
          'Agriculture',
          'Health Assistant',
          'Computers',
          'Construction',
          'Mechanic/Machinist',
          'Engineering',
          'Food and Hospitality',
        ],
        I: [
          'Marine Biology',
          'Engineering',
          'Chemistry',
          'Zoology',
          'Medicine/Surgery',
          'Consumer Economics',
          'Psychology',
        ],
        A: [
          'Communications',
          'Cosmetology',
          'Fine and Performing Arts',
          'Photography',
          'Radio and TV',
          'Interior Design',
          'Architecture',
        ],
        S: [
          'Counseling',
          'Nursing',
          'Physical Therapy',
          'Travel',
          'Advertising',
          'Public Relations',
          'Education',
        ],
        E: [
          'Fashion Merchandising',
          'Real Estate',
          'Marketing/Sales',
          'Law',
          'Political Science',
          'International Trade',
          'Banking/Finance',
        ],
        C: [
          'Accounting',
          'Court Reporting',
          'Insurance',
          'Administration',
          'Medical Records',
          'Banking',
          'Data Processing',
        ],
      }

      // Populate compatible industries based on the top 3 interest codes
      this.topInterestCodes.forEach(code => {
        this.compatibleIndustries.push(...industries[code])
      })
    },
  },
}
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
